export const names = [
    'John',
    'Raoul',
    'Jane',
    'Marcel',
    'Ibrahim',
    'Junko',
    'Lyu',
    'André',
    'Maki',
    'Véronique',
    'Thibeau',
    'Josiane',
    'Raphaël',
    'Mathéo',
    'Margot',
    'Hugo',
    'Christian',
    'Louis',
    'Ella',
    'Alton',
    'Jimmy',
    'Guillaume',
    'Sébastien',
    'Alfred',
    'Bon',
    'Solange',
    'Kendrick',
    'Jared',
    'Satoko',
    'Tomoko',
    'Line',
    'Delphine',
    'Leonard',
    'Alphonse',
    'Lisa',
    'Bart',
    'Benjamin',
    'Homer',
    'Jack',
]
