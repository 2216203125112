export const programmingLanguages = [
    'php',
    'make',
    'javascript',
    'go',
    'erlang',
    'elixir',
    'lisp',
    'haskell',
    'python',
    'ruby',
    'hack',
    'scala',
    'java',
    'rust',
    'c',
    'css',
    'sass',
    'stylus',
]
